import React, { useState } from "react";
import { MODULE_TITLES } from "../../../config/moduleConfig.js";
import { FormikProvider, useFormik } from "formik";
import { Card, Col } from "antd";
import * as Yup from "yup";
import { Form, Input, SubmitButton, Select } from "formik-antd";
import { useEffect } from "react";
import {
  useCityListLazyQuery,
  useStateListLazyQuery,
} from "../../../graphql/generated/index.tsx";
import { ConditionalComponent } from "../../common/GeneralComponents.js";
import { USER_ADDRESS_SLUG } from "../../../config/constants.js";
import PhoneInput from "react-phone-input-2";

const quotationBillingAddressSchema = Yup.object().shape({
  addressLine1: Yup.string().required("Street or Area is required"),
  landMark: Yup.string(),
  stateId: Yup.object().required("State is required"),
  cityId: Yup.object().required("City is required"),
  pinCode: Yup.number()
    .required("Pin Code is required")
    .test(
      "isSixDigits",
      "Pin Code must be exactly six digits",
      (value) => String(value).length === 6
    ),
  phoneNumber: Yup.number()
    .required("Contact is required")
    .test(
      "isTenDigits",
      "Contact must be 10 digits",
      (value) => String(value).length === 10
    ),
  gstNumber: Yup.string()
    .required("Please enter your password")
    .matches(
      /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/,
      "GST is Not valid "
    ),
  addressType: Yup.string().required("Address Type is required"),
  otherAddressTypeName: Yup.string().when("addressType", {
    is: (addressType) => addressType === "Other",
    then: Yup.string().required("Other Address Type is required"),
  }),
});

// regex for GST = “^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$”;

const AddNewBillingAddress = ({
  setNewBillingAddressModalVisible,
  setNewBillingAddress,
  shouldResetForm,
  setShouldResetForm,
}) => {
  const [findState, setFindState] = useState(null);
  const [findCity, setFindCity] = useState(null);
  const [otherAddressType, setOtherAddressType] = useState(null);

  {
    /**GraphQl */
  }

  const [getStateListData, { data: stateListData, loading: stateListLoading }] =
    useStateListLazyQuery();
  useEffect(() => {
    getStateListData();
  }, []);

  const [getCityListData, { data: cityListData, loading: cityListLoading }] =
    useCityListLazyQuery({
      variables: {
        countryCode: findState?.country_code,
        stateCode: findState?.state_code,
      },
    });

  useEffect(() => {
    if (findState) {
      getCityListData();
    }
  }, [findState]);

  useEffect(() => {
    formik.setFieldValue("cityId", "", true);
  }, [findState]);

  const formik = useFormik({
    initialValues: {
      addressLine1: "",
      landMark: "",
      stateId: "",
      cityId: "",
      pinCode: "",
      phoneNumber: "",
      gstNumber: "",
      addressType: "",
      otherAddressTypeName: "",
    },
    validationSchema: quotationBillingAddressSchema,
    onSubmit: (values, actions) => {
      setNewBillingAddress(values);
      actions.setSubmitting(false);
      setNewBillingAddressModalVisible(false);
      formik.resetForm();
    },
  });

  const handleStateSelectChange = (value, option) => {
    const selectedDetails = stateListData.stateList.find(
      (stateId) => stateId._id === value
    );
    setFindState(selectedDetails);
    formik.setFieldValue("stateId", option, true);
  };

  const handleCitySelectChange = (valueCity, cityOption) => {
    formik.setFieldValue("cityId", cityOption, true);
    const selectedCityDetails = cityListData.cityList.find(
      (cityId) => cityId._id === valueCity
    );
    setFindCity(selectedCityDetails);
  };

  useEffect(() => {
    if (otherAddressType !== "Other") {
      formik.setFieldValue("otherAddressTypeName", "", true);
    }
  }, [otherAddressType]);

  useEffect(() => {
    if (shouldResetForm) {
      formik.setValues({
        addressLine1: "",
        landMark: "",
        stateId: "",
        cityId: "",
        pinCode: "",
        phoneNumber: "",
        gstNumber: "",
        addressType: "",
        otherAddressTypeName: "",
      });
      // Clear form errors manually
      Object.keys(formik.errors).forEach((field) => {
        formik.setFieldError(field, "");
      });
      formik.setTouched({}); // Clear touched fields
      formik.setStatus({}); // Clear form status
      setShouldResetForm(false); // Reset the trigger
    }
  }, [shouldResetForm]);
  return (
    <Card>
      <FormikProvider value={formik}>
        <Form
          labelAlign="left"
          labelWrap={true}
          name="add-edit-category-page-form"
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 18 }}
          onSubmit={formik.handleSubmit}
        >
          <Form.Item label="Phone Number" name="phoneNumber" required>
            <PhoneInput
              country={"in"}
              enableSearch={true}
              countryCodeEditable={false}
              inputProps={{
                id: "phonenumber",
                required: true,
                autoFocus: true,
                className: `react-tel-input form-control `,
              }}
              onChange={(value, data) => {
                if (value.replace(data.dialCode, "") === "") {
                  formik.setErrors({
                    ...formik.errors,
                    phoneNumber: "Contact is required",
                  });
                  return;
                } else if (data && data.dialCode && value.length > 5) {
                  formik.setValues({
                    ...formik.values,
                    countryCode: `+${data.dialCode}`,
                    phoneNumber: `+${value}`.replace(`+${data.dialCode}`, ""),
                  });
                }
              }}
              value={formik.values.countryCode + formik.values.phoneNumber}
              onBlur={() => formik.setFieldTouched("phoneNumber", true, false)}
            />
          </Form.Item>

          <Form.Item label="House No. & Area" name="addressLine1" required>
            <Input
              type="text"
              id="addressLine1"
              name="addressLine1"
              onChange={formik.handleChange}
              placeholder=" House No. and Area..."
              suffix={<span />}
            />
          </Form.Item>

          <Form.Item label="LandMark" name="landMark" className="mr-2">
            <Input
              type="text"
              id="landMark"
              name="landMark"
              onChange={formik.handleChange}
              placeholder="LandMark..."
              suffix={<span />}
            />
          </Form.Item>

          <Form.Item label="State" name="stateId" required>
            <Select
              name="stateId"
              style={{ minWidth: 200 }}
              allowClear
              placeholder="Select State..."
              loading={stateListLoading}
              options={stateListData?.stateList.map((stateId) => ({
                label: stateId.name,
                value: stateId._id,
              }))}
              onChange={handleStateSelectChange}
            />
          </Form.Item>

          <Form.Item label="City" name="cityId" required>
            <Select
              name="cityId"
              style={{ minWidth: 200 }}
              allowClear
              loading={cityListLoading}
              placeholder="Select City..."
              options={cityListData?.cityList.map((cityId) => ({
                label: cityId.name,
                value: cityId._id,
              }))}
              onChange={handleCitySelectChange}
            />
          </Form.Item>

          <Form.Item label="PinCode" name="pinCode" required>
            <Input
              type="number"
              id="pinCode"
              name="pinCode"
              placeholder="PinCode..."
              suffix={<span />}
              onChange={(e) =>
                formik.setFieldValue("pinCode", e.target.value.slice(0, 6))
              }
            />
          </Form.Item>

          <Form.Item label="GST Number" name="gstNumber" required>
            <Input
              type="text"
              id="gstNumber"
              name="gstNumber"
              placeholder="GST Number..."
              suffix={<span />}
              onChange={(e) =>
                formik.setFieldValue(
                  "gstNumber",
                  e.target.value.toUpperCase().slice(0, 15)
                )
              }
            />
          </Form.Item>

          <Form.Item label="Address Type" name="addressType" required>
            <Select
              name="addressType"
              style={{ minWidth: 200 }}
              allowClear
              placeholder="Select addressType..."
              onChange={(item) => setOtherAddressType(item)}
              options={Object.values(USER_ADDRESS_SLUG).map((val) => ({
                label: val,
                value: val,
              }))}
            />
          </Form.Item>

          <ConditionalComponent shouldShow={otherAddressType === "Other"}>
            <Form.Item
              label="Other Address"
              name="otherAddressTypeName"
              required
            >
              <Input
                type="text"
                id="otherAddressTypeName"
                name="otherAddressTypeName"
                onChange={formik.handleChange}
                placeholder="Enter Other Address..."
                suffix={<span />}
              />
            </Form.Item>
          </ConditionalComponent>

          <Col sm={12} md={14} className="text-right">
            <SubmitButton
              loading={formik.isSubmitting}
              type="primary"
              htmlType="submit"
            >
              {MODULE_TITLES.COMMON.SAVE}
            </SubmitButton>
          </Col>
        </Form>
      </FormikProvider>
    </Card>
  );
};

export default AddNewBillingAddress;
