import React, { useEffect } from "react";
import { MODULE_TITLES } from "../../../config/moduleConfig.js";
import { FormikProvider, useFormik } from "formik";
import { Card, Col, notification } from "antd";
import * as Yup from "yup";
import { Form, Input, SubmitButton } from "formik-antd";
import { commonValidationMessage } from "../../../helper/MessagesHelper.js";
import { useCreateUserMutation } from "../../../graphql/generated/index.tsx";
import PhoneInput from "react-phone-input-2";

const quotationBillingAddressSchema = Yup.object().shape({
  email: Yup.string().required(commonValidationMessage("Email")).email(),
  buyerName: Yup.string().required(commonValidationMessage("Name")),
  phoneNumber: Yup.number().required("Phone number is required"),
});

const AddNewBuyer = ({
  isNewBuyerAddedModalVisible,
  setNewBuyerAddedModalVisible,
  setNewBuyer,
  shouldResetForm,
  setShouldResetForm,
}) => {
  const [createUser, { loading: createUserLoading }] = useCreateUserMutation(
    {}
  );

  const formik = useFormik({
    initialValues: {
      buyerName: "",
      phoneNumber: "",
      email: "",
    },

    validationSchema: quotationBillingAddressSchema,
    onSubmit: (values, actions) => {
      createUser({
        variables: {
          input: {
            phoneNumber: Number(values?.phoneNumber),
            email: values?.email,
            name: values?.buyerName,
            countryCode: +values.countryCode.replace("+", ""),
            devices: [
              {
                platformType: "WEB",
              },
            ],
          },
        },
        onCompleted(data) {
          setNewBuyer(data);
          notification.success({
            message: "New Buyer Added Successfully",
          });
          //   actions.resetForm();
          //   setNewBuyerAddedModalVisible(false);
        },
        onError(error) {
          notification.error({ message: error.message });
          setNewBuyerAddedModalVisible(true);
        },
      });

      actions.setSubmitting(false);
      setNewBuyerAddedModalVisible(false);
      formik.resetForm();
    },
  });

  useEffect(() => {
    if (shouldResetForm) {
      formik.setValues({
        buyerName: "",
        phoneNumber: "",
        email: "",
      });

      // Clear form errors manually
      Object.keys(formik.errors).forEach((field) => {
        formik.setFieldError(field, "");
      });

      formik.setTouched({}); // Clear touched fields
      formik.setStatus({}); // Clear form status

      setShouldResetForm(false); // Reset the trigger
    }
  }, [shouldResetForm]);

  return (
    <Card loading={createUserLoading}>
      <FormikProvider value={formik}>
        <Form
          labelAlign="left"
          labelWrap={true}
          name="add-edit-category-page-form"
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 18 }}
          onSubmit={formik.handleSubmit}
        >
          <Form.Item label="Name" name="buyerName" required>
            <Input
              type="text"
              id="buyerName"
              name="buyerName"
              placeholder="Enter Name.."
              suffix={<span />}
              onChange={(e) =>
                formik.setFieldValue("buyerName", e.target.value)
              }
            />
          </Form.Item>

          <Form.Item label="Email" name="email" required>
            <Input
              type="email"
              id="email"
              name="email"
              placeholder="Enter Email..."
              suffix={<span />}
              onChange={(e) =>
                formik.setFieldValue("email", e.target.value, true)
              }
            />
          </Form.Item>

          <Form.Item label="Phone Number" name="phoneNumber" required>
            <PhoneInput
              country={"in"}
              enableSearch={true}
              countryCodeEditable={false}
              inputProps={{
                id: "phonenumber",
                required: true,
                autoFocus: true,
                className: `react-tel-input form-control `,
              }}
              onChange={(value, data) => {
                if (value.replace(data.dialCode, "") === "") {
                  formik.setErrors({
                    ...formik.errors,
                    phoneNumber: "Contact is required",
                  });
                  return;
                } else if (data && data.dialCode && value.length > 5) {
                  formik.setValues({
                    ...formik.values,
                    countryCode: `+${data.dialCode}`,
                    phoneNumber: `+${value}`.replace(`+${data.dialCode}`, ""),
                  });
                }
              }}
              value={formik.values.countryCode + formik.values.phoneNumber}
              onBlur={() => formik.setFieldTouched("phoneNumber", true, false)}
            />
          </Form.Item>

          <Col sm={12} md={14} className="text-right">
            <SubmitButton
              loading={formik.isSubmitting}
              type="primary"
              htmlType="submit"
            >
              {MODULE_TITLES.COMMON.SAVE}
            </SubmitButton>
          </Col>
        </Form>
      </FormikProvider>
    </Card>
  );
};

export default AddNewBuyer;
