import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import Main from './container/Main'

/* Styles */
import './assets/less/index.less';
import './assets/scss/index.scss';
import "react-phone-input-2/lib/style.css";
import { PaginationProvider } from './context/PaginationContext';

export const App = () => {

    return (
        <BrowserRouter>
            <PaginationProvider>
                <Main />
            </PaginationProvider>
        </BrowserRouter>
    )
}

export default App;